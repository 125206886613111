
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { eModeType, eMenuIconTypes } from '@/enums';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import { draggableOptions } from '@/constants';
import SitchImageUpload from '@/components/custom-ui-components/SitchImageUpload.vue';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { getModeIcon } from '@/util-functions/misc-utils';
import { isPremiumActivated } from '@/util-functions/user-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchImageUpload,
  },
  data(): {
    draggableOptions: Record<string, unknown>;
    selectedSitePageModes: Mode[];
    modeForm: Omit<SiteMode, keyof Mode>;
    menuIconOptions: TextValue[];
    eMenuIconTypes: typeof eMenuIconTypes;
    imageSizes: ImageSizes;
  } {
    return {
      draggableOptions: draggableOptions,
      imageSizes: { small: 200 },
      menuIconOptions: [
        {
          text: t.standard,
          value: eMenuIconTypes.standard,
        },
        {
          text: t.none,
          value: eMenuIconTypes.none,
        },
        {
          text: t.emoji,
          value: eMenuIconTypes.emoji,
        },
      ],
      selectedSitePageModes: [],
      modeForm: {} as Omit<SiteMode, keyof Mode>,
      eMenuIconTypes,
    };
  },
  computed: {
    currUserModeGateway(): PublicUserModeGateway {
      return this.$store.state.currUserModeGateway;
    },
    isPremiumActivated(): boolean {
      return isPremiumActivated();
    },
    modes(): Record<string, AnyMode> {
      return this.$store.state.modes;
    },
    modesForPages(): Mode[] {
      const usedNameCount: { [name: string]: number } = {};
      return (Object.values(this.modes) as Mode[])
        .filter((mode: Mode) => {
          return ![eModeType.site].includes(mode.type as eModeType);
        })
        .map((mode: Mode) => {
          usedNameCount[mode.name] = usedNameCount[mode.name] ? usedNameCount[mode.name] + 1 : 1;
          if (usedNameCount[mode.name] > 1) {
            return { ...mode, name: `${mode.name} (${mode.displayName || usedNameCount[mode.name]})` };
          }
          return mode;
        })
        .sort((a: Mode, b: Mode) => {
          return a.index - b.index;
        });
    },
    selectedModesThatAreNotRedirects(): Mode[] {
      const selectedSitePageModes = this.selectedSitePageModes;
      if (selectedSitePageModes) {
        return selectedSitePageModes.filter((mode: Mode) => mode.type !== eModeType.urlRedirect);
      }
      return [];
    },
  },
  methods: {
    onRemoveMode(modeDocId: string) {
      this.selectedSitePageModes = this.selectedSitePageModes.filter((mode, index) => {
        if (mode.docId === modeDocId) {
          this.modeForm.emojiIcons.splice(index, 1);
          if (modeDocId === this.modeForm.landingPageModeId) {
            this.modeForm.landingPageModeId = '';
          }
          return false;
        }
        return true;
      });
    },
    onFormPopulatedForEdit() {
      // If we're editing a site mode then populate the selected site pages list.
      if (this.modeForm.sitePageModeIds?.length) {
        this.selectedSitePageModes = this.modeForm.sitePageModeIds
          .map((modeId: string) => {
            return this.modes[modeId];
          })
          .filter((mode) => Boolean(mode));
      }
    },
    onOrderChange(value: { moved: { oldIndex: number; newIndex: number } }) {
      if (this.modeForm.emojiIcons) {
        // Make the arrays the same length if they are not already.
        if (this.modeForm.emojiIcons.length !== this.selectedSitePageModes.length) {
          this.selectedSitePageModes.forEach((mode, index) => {
            this.modeForm.emojiIcons[index] = this.modeForm.emojiIcons[index] || '';
          });
        }
        const oldIndex = value.moved.oldIndex;
        const newIndex = value.moved.newIndex;
        const emojiToMove = this.modeForm.emojiIcons[oldIndex];
        this.modeForm.emojiIcons.splice(oldIndex, 1);
        this.modeForm.emojiIcons.splice(newIndex, 0, emojiToMove);
      }
    },
    getEmojiPlaceholder(index: number) {
      const mode = this.selectedSitePageModes[index];
      if (!mode) {
        return '❓';
      }
      switch (mode.type) {
        case eModeType.profile:
          return '🖐';
        case eModeType.urlRedirect:
          return '🌎';
        case eModeType.article:
          return '📰';
        case eModeType.files:
          return '🗄️';
        case eModeType.personalPayment:
          return '💵';
        case eModeType.businessPayment:
          return '🤝';
        case eModeType.wifi:
          return '📡';
        case eModeType.site:
          return '🌎';
        case eModeType.linkList:
          return '☑️';
        case eModeType.gallery:
          return '🖼️';
        case eModeType.group:
          return '📇';
        case eModeType.shop:
          return '🏪';
        case eModeType.html:
          return '🌎';
        case eModeType.events:
          return '📅';
        case eModeType.booking:
          return '📆';
        case eModeType.customForm:
          return '📋';
        case eModeType.blog:
          return '📝';
        case eModeType.accordion:
          return '📖';
        case eModeType.returnTo:
          return '↩';
        case eModeType.chat:
          return '💬';
        case eModeType.chess:
          return '♟';
        case eModeType.wordle:
          return '🔠';
        case eModeType.trivia:
          return '🤔';
        case eModeType.card:
          return '🃏';
        default:
          return '❓';
      }
    },
    getModeIcon(type: eModeType) {
      return getModeIcon(type);
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      this.modeForm.sitePageModeIds = this.selectedSitePageModes.map((mode: Mode) => {
        return mode.docId;
      });

      const combinedModeData = getCombinedModeFormData(this) as SiteMode;

      if (!combinedModeData.landingPageModeId) {
        showError(t?.mustSelectLandingPageModeId);
        return;
      }

      const promiseArray: Promise<any>[] = [];
      const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

      handleImages(
        combinedModeData,
        [
          {
            key: 'images',
            imageSizes: this.imageSizes,
          },
        ],
        promiseArray,
        storagePath
      );

      Promise.all(promiseArray).then(() => {
        saveMode({ ...combinedModeData });
      });
    },
  },
});
